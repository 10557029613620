import { assign, forEach, get, map, pick } from 'lodash';

import CellBorder from './CellBorder';
import DealNumberFormat from './DealNumberFormat';
import { BASE_NUMBERING } from './DealStyle';

export const ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
  BOTH: 'both',
};

export const SIDES = [
  { key: 'top', title: 'Top' },
  { key: 'right', title: 'Right' },
  { key: 'bottom', title: 'Bottom' },
  { key: 'left', title: 'Left' },
];

// This is the start of a model for Section-level style config data
// We can add to this as we continue to build out Draft functionality

export default class SectionStyle {
  raw = null;

  align = null;
  columns = null;
  numbering = null;
  borders = {
    top: null,
    right: null,
    bottom: null,
    left: null,
  };
  padding = {
    top: null,
    right: null,
    bottom: null,
    left: null,
  };
  indentation = null;

  constructor(json) {
    this.raw = json;

    if (json) {
      assign(this, pick(json, ['align', 'columns', 'indentation']));
      // If we have custom numbering specified, ensure that it's always handled as an array
      // And that we strongly type each level as a DealNumberFormat
      // This also aligns the data structure of Section.style.numbering[] with Appendix.style.numbering[]
      if (json.numbering) {
        if (Array.isArray(json.numbering)) {
          this.numbering = map(
            json.numbering,
            (level, idx) => new DealNumberFormat(level || BASE_NUMBERING[idx] || BASE_NUMBERING[0])
          );
        } else {
          this.numbering = [new DealNumberFormat(json.numbering)];
        }
      }
    }

    forEach(SIDES, (side) => {
      this.borders[side.key] = new CellBorder(get(json, `borders.${side.key}`) || {});
      this.padding[side.key] = get(json, `padding.${side.key}`, null);
    });
  }

  get jsonBorders() {
    return {
      borders: {
        top: this.borders.top.json,
        right: this.borders.right.json,
        bottom: this.borders.bottom.json,
        left: this.borders.left.json,
      },
    };
  }

  get jsonPadding() {
    return {
      padding: {
        top: this.padding.top || null,
        right: this.padding.right || null,
        bottom: this.padding.bottom || null,
        left: this.padding.left || null,
      },
    };
  }

  get isAligned() {
    return !!get(this, 'raw.align');
  }

  get css() {
    return {
      borderTop: this.borders.top.css,
      borderRight: this.borders.right.css,
      borderBottom: this.borders.bottom.css,
      borderLeft: this.borders.left.css,
      paddingTop: this.padding.top,
      paddingRight: this.padding.right,
      paddingBottom: this.padding.bottom,
      paddingLeft: this.padding.left,
    };
  }
}
