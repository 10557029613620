import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import {
  CompositeDecorator,
  ContentState,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
  getDefaultKeyBinding,
} from 'draft-js';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';
import SectionType from '@core/enums/SectionType';
import { DATA_SOURCE_TYPES } from '@core/models/AIPrompt';
import {
  ENTITY_TYPE,
  bodyBindings,
  containsEntity,
  findVariableSuggestTarget,
  getMarkup,
  isAtEnd,
  isAtStart,
  parseExternalHTML,
  rxSuggest,
  rxSuggestFields,
  strategies,
  suggestElement,
  titleBindings,
  tokenizeVariables,
} from '@core/models/Content';
import { INDENT_TYPES } from '@core/models/IndentationStyle';
import { FEATURE_LABEL, LIST_TYPES } from '@core/models/List';
import Section from '@core/models/Section';
import TableColumn from '@core/models/TableColumn';
import Variable, { CONNECTED_VAR_FIELDS, ValueType, VariableKey, VariableType } from '@core/models/Variable';
import { sanitize } from '@core/models/Version';
import { APPENDIX_NAMES, SECTION_NAMES } from '@core/parsing/Legalese';
import { Dt, dt } from '@core/utils/Environment';
import { formatOrder } from '@core/utils/OrderFormatter';
import stateFromHTML from '@core/utils/StateFromHTML';

import { Alert, Button, Icon } from '@components/dmp';
import ButtonIcon from '@components/dmp/ButtonIcon';

import FootnoteDisplay from '@components/FootnoteDisplay';
import PageBreak from '@components/PageBreak';
import TableView from '@components/TableView';
import ConditionsView from '@components/editor/ConditionsView';
import SectionSuggest from '@components/editor/SectionSuggest';
import TableColumnEditor from '@components/editor/TableColumnEditor';
import TooltipButton from '@components/editor/TooltipButton';
import VariableEditor from '@components/editor/VariableEditor';
import VariableSuggest from '@components/editor/VariableSuggest';
import TemplateSelector from '@components/teams/TemplateSelector';
import Fire from '@root/Fire';
import { compareConnectedVariableDefinitions } from '@root/utils/VariableIndex';
import VariableIndex from '@root/utils/VariableIndex';

import LockTakeover from './LockTakeover';

@autoBindMethods
export default class SectionEditor extends Component {
  static propTypes = {
    section: PropTypes.instanceOf(Section).isRequired,
    container: PropTypes.object,
    tocKey: PropTypes.string.isRequired,
    editVariable: PropTypes.func.isRequired,
    updateMessage: PropTypes.func.isRequired,
    unlinkCL: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    moveFocus: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onColConfig: PropTypes.func.isRequired,
    keepAlive: PropTypes.func.isRequired,
    userID: PropTypes.string.isRequired,
    linkingSummaryID: PropTypes.string,
    linkingLensID: PropTypes.string,
    linkingAIBlockID: PropTypes.string,
    toolbar: PropTypes.object,
    variableIndex: PropTypes.instanceOf(VariableIndex),
    lock: PropTypes.object,
    overviewMode: PropTypes.bool,
    templateRef: PropTypes.object,
    handleLockSection: PropTypes.func.isRequired,
    handleUnlockSection: PropTypes.func.isRequired,
  };

  static defaultProps = {
    readonly: false,
  };

  constructor(props) {
    super(props);

    this.compositeDecorator = new CompositeDecorator([
      // Find element entities and colorize, tokenize and make clickable
      // Note, it would be preferable to separate this component instead of having inline,
      // but currently it has closures (references to "this") so needs to be inline until we refactor
      {
        strategy: strategies.variable,
        component: (p) => {
          const { section, variableIndex } = this.props;
          const { loadedFVConnectedDefinitions } = this.state;
          const text = p.decoratedText;
          let varName = text.substring(2, text.length - 1).split('.')[0];
          const varType = VariableKey[text[1]];

          // Don't colorize elements with spaces in names
          // References are an exception as they will display as [$Section 4.1] (for example)
          if (text.indexOf(' ') > -1 && text[1] != VariableType.REF) return <span>{p.children}</span>;

          const onClick = (e) => {
            this.selectDecoratedComponent(p);
            this.editEntity(e.target, p.decoratedText);
          };

          // There are 2 scenarios where we need to "mask" the actual underlying text
          // And instead render the actual value (if there is one)
          // First is Reference elements, which will have text like: [$ref-<sectionID>]
          // The second is when we're in Draft mode, i.e., user is authoring an individual contract (not a template)
          let mask, display, broken, hasImage, disabled, resolve, loading;
          let element = varName ? section.deal.variables[varName] : null;
          if ((text[1] == VariableType.REF || section.deal.inDraft) && p.entityKey) {
            let ent = p.contentState.getEntity(p.entityKey);
            if (ent && ent.getData()) varName = ent.getData().name || varName;
            if (varName) element = section.deal.variables[varName];
            if (element) {
              if (element.val && element.type === VariableType.SIMPLE && element.valueType === ValueType.IMAGE) {
                // Special case for images where we actually need a React element
                display = <img src={element.val} alt={element.displayName} />;
                hasImage = true;
              } else if (element.valueType === ValueType.TABLE) {
                display = element.displayName || element.name;
              } else {
                display = element.val || element.displayName || element.name;
              }
            }

            mask = true;
          }

          if (element?.isBroken) broken = true;
          //Don't allow users to modify connected variables while we are checking their definitions against fv's incomming definitions
          if (element?.isConnected) {
            if (loadedFVConnectedDefinitions) {
              const comparison = compareConnectedVariableDefinitions(variableIndex, element);

              broken = comparison.broken;
              resolve = comparison.resolve;
              disabled = false;
              loading = false;
            } else {
              disabled = true;
              loading = true;
            }
          }

          // Calculated variables with properties are table column totals
          // These should not have any configurable properties (don't show VariableEditor)
          if (text.includes('.') && (text[1] === VariableType.CALCULATED || element?.valueType === ValueType.TABLE)) {
            disabled = true;
          }

          if (text[1] === VariableType.FOOTNOTE) {
            //get the masked value for the footnote in order
            const footnotes = section.footnotesFromText;
            //we need to make the index check to properly sort
            const footnoteNumber = _.findIndex(footnotes, (footnote) => {
              return footnote === varName;
            });

            const footnoteFormatted = formatOrder(footnoteNumber, section.deal.footnoteConfig.numberFormat);

            const variable = _.find(section.variables, { name: varName });

            broken = !variable?.value;
            display = `[^${footnoteFormatted}]`;
            mask = true;
          }

          if (text[1] === VariableType.PAGE_COUNT) {
            const section = this.props.section.isTemplateHeaderFooterSubSection
              ? this.props.section.sourceParent
              : this.props.section;

            display = section.headerFooterNumbering.format('#', '#');
            mask = true;
          }

          const className = cx(
            'template-variable',
            varType,
            { broken },
            { 'has-image': hasImage },
            { disabled },
            { linked: section.shouldSyncWithCL },
            { loading }
          );

          // Finally we render either the (styled) underlying text, e.g., [#My-Element]
          // Or a masked version, which strips out/replaces the syntax but still includes the underlying content (p.children)
          // This masking is necessary because without rendering p.children, DraftJS goes haywire
          return (
            <span className={className} onClick={onClick} data-cy={`template-${varType.toLowerCase()}-variable`}>
              {resolve && <div className="resolve"></div>}
              {mask && <span className="displayName">{display}</span>}
              {!broken && (mask ? <span className="mask">{p.children}</span> : p.children)}
              {broken && element?.isCalculated && (
                <TooltipButton tip={`${!element.formula ? 'Formula empty' : element.val || '<empty>'}`}>
                  <span>{p.children}</span>
                </TooltipButton>
              )}
              {broken && !element?.isCalculated && <span>{p.children}</span>}
            </span>
          );
        },
      },
      //suggest linking section references
      {
        strategy: strategies.candidateRef,
        component: (p) => {
          const onClick = (e) => {
            this.selectDecoratedComponent(p);
            this.editEntity(e.target, `[$${p.decoratedText}]`, true);
          };
          //upon clicking, need to select whole decorated component before passing to variable editor
          //this way the whole component text is replaced with output from variable editor
          return (
            <span className="candidate-ref" data-cy="candidate-ref" onClick={onClick}>
              {p.children}
            </span>
          );
        },
      },
      // Suggest other candidate elements for inbound deals
      {
        strategy: strategies.fieldFinder,
        component: (p) => {
          const onClick = (e) => {
            const field = this.selectDecoratedComponent(p);
            const candidate = suggestElement(p.contentState, field);
            this.editEntity(e.target, `[${candidate.type}${candidate.name}]`, true, candidate);
          };
          //upon clicking, need to select whole decorated component before passing to variable editor
          //this way the whole component text is replaced with output from variable editor
          return (
            <span className="candidate-element" onClick={onClick}>
              {p.children}
            </span>
          );
        },
      },
      // Repeater fields open TableColumnConfig popover
      {
        strategy: strategies.repeaterField,
        component: (p) => (
          <span className="template-variable FIELD" onClick={(e) => this.editColumn(e.target, p.decoratedText)}>
            {p.children}
          </span>
        ),
      },
      //highlight text during variable suggest (auto-complete)
      {
        strategy: _.get(this.props, 'section.isRepeater')
          ? strategies.variableFieldSuggest
          : strategies.variableSuggest,
        component: (p) => {
          // We need to create a ref specific to THIS instance of the potential variable
          // so we can use the blockKey and start as a unique ref id
          const refKey = `${p.blockKey}|${p.start}`;
          this.refSuggestTargets[refKey] = createRef();
          return (
            <span className="var-suggest" ref={this.refSuggestTargets[refKey]}>
              {p.children}
            </span>
          );
        },
      },
    ]);

    const source = props.section ? props.section.content : null;

    this.state = {
      editorState: this.createBodyEditorState(source),
      titleState: this.createTitleEditorState(),
      editorFocus: this.getDefaultFocusField(),
      entity: null, //will cause a VariableEditor popover to show for entity editing when not null
      editingColumn: null, //will cause a TableColumnEditor popover to show for entity editing when not null
      entityTarget: null, //anchor span in editor to use for popover positioning
      candidate: null, //additional helper object for ingestion, passed to VariableEditor
      entityCreating: false, //whether we're creating a new entity (variable) or editing existing one
      deleting: false, //whether user is currently deleting a section (with popover confirmation)
      focused: false,
      vsTarget: false, //target element if VariableSuggest is active
      clauseLookup: false,
      loadedFVConnectedDefinitions: false,
      connectedVariables: this.connectedVariables(props),
    };

    this.autosave = _.debounce(this.save, 1000);
    this.toolbar = createRef();
    this.refSuggest = createRef();
    this.refCL = createRef();
    this.refKBCL = createRef();
    this.refSelf = createRef();
    this.refSuggestTargets = {};
  }

  //retrieve connected variables from a section
  //needed to check if connected variables have updated,
  //or been added via linked sections to update in realtime
  connectedVariables(props) {
    const variables = _.map(props.section.variables, (v) => {
      return v.json;
    });
    return _.filter(variables, { type: VariableType.CONNECTED });
  }

  //anytime a save is made we get a new deal
  //if there are structural changes, we need to update the
  UNSAFE_componentWillReceiveProps(props) {
    const { tocKey, section, variableIndex } = props;
    const { connectedVariables, loadedFVConnectedDefinitions } = this.state;

    if (tocKey != this.props.tocKey && _.find(section.variables, { type: '$' })) {
      const editorState = this.createBodyEditorState(section.content);
      this.setState({ editorState });
    }

    if (!_.isEmpty(this.connectedVariables(props))) {
      //Once the connected variable definitions are loaded into the variableIndex (in DealView) update state so we only rerender the section once.
      if (variableIndex.index.l[VariableType.CONNECTED] && !loadedFVConnectedDefinitions && !this.hasFocus) {
        this.setState({ loadedFVConnectedDefinitions: true });
        //re-render the section to update the EditorState with the new section style based on compared connected variable definitions
        this.rerender(section);
      }
      //When we resolve a variable definiton we need to update the section style by rerendering.
      if (loadedFVConnectedDefinitions) {
        if (!_.isEqual(this.connectedVariables(props), connectedVariables)) {
          this.setState({ connectedVariables: this.connectedVariables(props) });
          //If the user is not editing, rerender
          !this.hasFocus && this.rerender(section);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { lock, section, updateMessage, userID } = this.props;

    //rerender whenever we find a new footnote
    if (section.footnotesCount !== prevProps.section.footnotesCount) {
      this.rerender(section);
    }

    //rerender if the number format changes
    if (section.deal.footnoteConfig.numberFormat.label !== prevProps.section.deal.footnoteConfig.numberFormat.label) {
      this.rerender(section);
    }

    //We need to update eithe the parent or current section when updating page number style on template headers and footers
    if (section.isTemplateHeaderFooter) {
      const currentHeaderFooterSection = section.isTemplateHeaderFooterSubSection ? section.sourceParent : section;

      const prevHeaderFooterSection = prevProps.section.isTemplateHeaderFooterSubSection
        ? prevProps.section.sourceParent
        : prevProps.section;

      if (
        currentHeaderFooterSection.headerFooterNumbering.title !== prevHeaderFooterSection.headerFooterNumbering.title
      ) {
        this.rerender(section);
      }
    }

    // If a section becomes locked while editing (which can happen after timeout)
    // Ensure that we unfocus it
    if (lock && this.hasFocus && !prevProps.lock) {
      this.blur();

      //If being edited by someone else
      if (lock.uid !== userID) {
        updateMessage(`${lock.displayName} took over the section you were editing`, 'error');
      }
    }

    if (prevProps.section.shouldSyncWithCL !== section.shouldSyncWithCL) {
      this.rerender(section);
    }

    // See if title or body content actually changed
    const textChanged =
      !_.isEqual(section.content, prevProps.section.content) ||
      !_.isEqual(section.displayname, prevProps.section.displayname);

    // If so, and this section is NOT focused (ie, another user editing different section that affected this one), update!
    if ((!this.hasFocus || section.shouldSyncWithCL) && textChanged) {
      this.rerender(section);
    }

    // If this section IS focused, it's likely because user is actually editing -- if so, ignore (otherwise cursor would jump after save)
    // But if section is still *selected* but neither Editor input actually has focus in the DOM, update
    // This covers cases where content was changed elsewhere (e.g., Elements sidebar) while section is selected
    if (this.hasFocus && textChanged && !this.isTitleFocused && !this.isBodyFocused) {
      this.rerender(section);
    }
  }

  async rerender(section) {
    const newState = {};

    if (section.displayname) {
      newState.titleState = this.createTitleEditorState();
    }
    if (section.content) {
      newState.editorState = this.createBodyEditorState(section.content);
    }

    return this.setState(newState);
  }

  selectDecoratedComponent(p) {
    const sel = this.state.editorState.getSelection();
    let newSel;
    const block = this.state.editorState.getCurrentContent().getBlockForKey(sel.getAnchorKey());
    const text = block.getText();
    let start = sel.getStartOffset(),
      idx = -1,
      end = -1,
      search = 0;
    //search through text and find the correct target instance of the component
    //this is important as it may occur multiple times
    //there's probably a way to do this via DraftJS API but documentation is fucking awful
    //so we're using good old text search
    while ((idx = text.indexOf(p.decoratedText, search)) > -1) {
      if (start >= idx && idx + p.decoratedText.length >= start) {
        start = idx;
        end = idx + p.decoratedText.length;
        break;
      } else search = idx + 1;
    }

    //assuming we find a match, select the text in the editor
    if (start > -1 && end > start) {
      newSel = SelectionState.createEmpty(block.getKey()).merge({ anchorOffset: start, focusOffset: end });
      this.setState({ editorState: EditorState.forceSelection(this.state.editorState, newSel) });
    }
    return newSel || sel;
  }

  getDefaultFocusField() {
    //only start focus on body if there's only body content and no title
    //or if we're looking at a summary section, default to no title
    if (!this.props.section.displayname && (this.props.section.content || this.isSummary)) return 'body';

    //if we get here (default), start focus on title
    return 'title';
  }

  get isSummary() {
    return (
      this.props.section && this.props.section.sectiontype == SectionType.SUMMARY && this.props.section.indentLevel == 1
    );
  }

  get isSummaryHeader() {
    return (
      this.props.section && this.props.section.sectiontype == SectionType.SUMMARY && this.props.section.indentLevel == 0
    );
  }

  get showTitle() {
    const { section } = this.props;
    const { editorFocus } = this.state;

    // SectionEditors inside CAPTIONs (ie columns) and Repeaters cannot have titles
    if (section.isCaption || section.isRepeater || section.continuous || section.isTimeline || section.isVinnie)
      return false;

    if (section.isTemplateHeaderFooter) return false;

    const alwaysShow = [SectionType.APPENDIX, SectionType.SIGNATURE].includes(section.sectiontype);
    return alwaysShow || editorFocus === 'title' || section.displayname;
  }

  get showBody() {
    const { section } = this.props;
    const { editorFocus } = this.state;

    // HEADER sections can't have bodies
    if (section.sectiontype === SectionType.HEADER) return false;

    // LINKED appendices bodies are explicitly managed via TemplateSelector
    if (section.appendixType === SectionType.LINKED) return false;

    // Always show body field for Appendix, Signature and Repeater sections
    if ([SectionType.APPENDIX, SectionType.SIGNATURE].includes(section.sectiontype) || section.isRepeater) return true;

    if (section.isTemplateHeaderFooter) return true;

    // LIST sections with continuous numbering don't have any of their own content
    if (section.isList && section.continuous) return false;

    // Always show body for SectionEditors inside CAPTIONs (ie columns)
    if (section.isCaption) return true;

    // Otherwise for normal sections, show if there's content, or if user is about to add content (already focused)
    return editorFocus === 'body' || !!section.currentVersion.getText('body');
  }

  //use Draft's utterly ridiculous fucking API to ensure that the user can't break section entities
  //this is necessary because sometimes even if the cursor visually appears at end of entity,
  //typing puts the cursor in the middle and edits the text inside the entity
  maskedEntityCheck(chars, editorState) {
    let handled = false;
    const es = this.state.editorState;
    const cs = es.getCurrentContent();
    const sel = this.state.editorState.getSelection();
    const block = cs.getBlockForKey(sel.getFocusKey());
    let focus = sel.getFocusOffset();
    let entityKey = block.getEntityAt(sel.getFocusOffset()),
      entity = null;

    if (entityKey) {
      entity = cs.getEntity(entityKey);
      if (
        entity &&
        (entity.getData().varType == VariableType.REF ||
          entity.getData().varType == VariableType.FOOTNOTE ||
          entity.getData().varType == VariableType.PAGE_COUNT ||
          this.props.section.deal.inDraft)
      ) {
        //if cursor is on a ref entity, move cursor forward until we're clear of it
        while (entityKey != null) entityKey = block.getEntityAt(++focus);

        //now take the characters (which should be just one) and insert them into the text
        //also compute selection to show up after newly entered text
        let newSel = SelectionState.createEmpty(block.getKey()).merge({
          anchorOffset: focus + chars.length,
          focusOffset: focus + chars.length,
        });
        let newContent = Modifier.insertText(cs, newSel, chars);
        let newState = EditorState.push(es, newContent);
        newState = EditorState.forceSelection(newState, newSel);
        this.setState({ editorState: newState });

        //returning handled=true prevents default Editor behavior
        handled = true;
      }
    }
    return handled;
  }

  onTitleChange(titleState) {
    const { updateMessage, keepAlive } = this.props;
    const { clauseLookup } = this.state;
    if (
      !_.isEqual(getMarkup(titleState.getCurrentContent()), getMarkup(this.state.titleState.getCurrentContent())) &&
      !clauseLookup
    ) {
      updateMessage('Saving...', 'info');
      this.autosave();
      keepAlive();
    }
    this.setState({ titleState });
    this.updateSelection(titleState, 'title');
  }

  onBodyChange(newState) {
    const { updateMessage, keepAlive, section } = this.props;
    const { editorState } = this.state;

    if (!_.isEqual(getMarkup(newState.getCurrentContent()), getMarkup(editorState.getCurrentContent()))) {
      this.autosave();
      updateMessage('Saving...', 'info');
      keepAlive();
    }
    this.setState({ editorState: newState }, () => {
      const vsTarget = !this.state.focused
        ? null
        : findVariableSuggestTarget({
            editorState: newState,
            refTargets: this.refSuggestTargets,
            rx: section.isRepeater ? rxSuggestFields : rxSuggest,
          });
      this.setState({ vsTarget });
    });
    this.updateSelection(newState, 'body');
  }

  // This is the very first point of interaction with the DraftJS Editor.
  // This enables us to intercept keyboard events and delegate them to the VariableSuggest component
  // Similar to DraftJS's own pattern -- see RichUtils in handleBodyCommand
  // So overall order of "bubbling" (which may skip #2 or #3) is:
  // 1. onBodyKeyDown
  // 2. bodyBindings
  // 3. handleBodyCommand
  // 4. onBodyChange
  onBodyKeyDown(e) {
    const { section } = this.props;
    const { editorState, vsTarget } = this.state;
    const varSuggest = this.refSuggest.current;

    // Allow VariableSuggest to hijack certain keys (enter, escape, arrows) if active
    if (vsTarget && varSuggest && varSuggest.handleKey(e)) return true;

    // Special case for captions (ie the columns inside captions)
    // hitting enter should just add a line break, not a new section
    if (e.key === 'Enter' && section.isCaption) {
      return getDefaultKeyBinding(e);
    }

    if (e.key === 'Enter' && section.isTemplateHeaderFooter) {
      return getDefaultKeyBinding(e);
    }

    return bodyBindings(e, editorState);
  }

  onTitleKeyDown(e) {
    const { section, updateMessage } = this.props;
    const { titleState } = this.state;
    const cl = this.refCL.current;

    if (e.key === '/') {
      if (
        section.canCL &&
        section?.currentVersion?.isPlaceholder &&
        titleState.getCurrentContent().getPlainText().trim() === '' &&
        !section.isSummary
      ) {
        this.setState({ clauseLookup: true });
        return true;
      }
    }

    // Only do inline CL lookup if there's no body text
    if (!this.hasBody && cl && cl.handleKey(e)) return true;

    // In normal cases, just handle keypress with extra commands (enter, tab, etc)
    return titleBindings(e, titleState);
  }

  async commitSuggestion(newText, option) {
    const { section } = this.props;
    const { vsTarget, editorState } = this.state;
    if (!vsTarget) return;

    const { block, start, input } = vsTarget;

    // We will likely have a partial search string already present, e.g., '[#va'
    // so select the whole thing before inserting (replacing) text
    const newSel = SelectionState.createEmpty(block.getKey()).merge({
      anchorOffset: start,
      focusOffset: start + input.length,
    });

    const newState = EditorState.forceSelection(editorState, newSel);
    await this.setState({ editorState: newState });
    this.insertText(newText);

    // VariableSuggest / VariableIndex is also used for "fields", which have {field} syntax
    // And different (simpler) data structure (TableColumn)
    if (option instanceof TableColumn) {
      this.saveColumn(option);
    }

    // If we select an external variable (Filevine, maybe others soon) that's not on the Deal yet, add it as a variable definition to the Deal!
    // This will trigger a Deal reload, at which point the external value will be loaded/synced in DealView
    if (
      option.connectType === FILEVINE_SERVICE.key &&
      !section.deal.variables[option.id] &&
      option.valueType !== ValueType.PROJECT
    ) {
      const varDef = _.pick(option, CONNECTED_VAR_FIELDS);
      Fire.saveVariableDefinition(section.deal, varDef);
    }

    if (option.type === VariableType.SIMPLE && option?.isNew) {
      // We will store all NEW simple variables.  The user is able to manually delete from the sidebar

      await Fire.saveVariableDefinition(section.deal, { ...option, name: option.name || option.value, value: null });
    }

    if (option.type === VariableType.FOOTNOTE && !(option instanceof Variable)) {
      await Fire.saveVariableDefinition(section.deal, option);
    }
  }

  createBodyEditorState(source) {
    if (source == null || source.trim() == '') return EditorState.createEmpty(this.compositeDecorator);

    let cs = stateFromHTML(source);
    cs = tokenizeVariables(cs);

    return EditorState.createWithContent(cs, this.compositeDecorator);
  }

  createTitleEditorState() {
    return EditorState.createWithContent(ContentState.createFromText(this.props.section.displayname || ''));
  }

  //auto focus appropriate field on edit
  async focus(field) {
    const { editorState, titleState } = this.state;
    const { onFocus, userID, section, lock } = this.props;

    // If the target section is already locked by a different user,
    // or if this section is linked to another section in clause library do nothing
    if (lock && lock.uid !== userID) return;

    // If the target section is linked to another section in CL, field has no meaning because it's not editable
    // but we still want to enable the whole section to be focused
    // and we also have an invisible (opacity = 0) input to manage subsequent keyboard focus!
    if (
      section.shouldSyncWithCL ||
      !this.hasEditors ||
      this.headerFooterSection.isDefault
      // || section.hasNumberAlignmentOverride
    ) {
      if (this.refKBCL.current) {
        this.refKBCL.current.focus();
      }
      await this.setState({ focused: true });
      onFocus(section);
      return;
    }

    if (field === 'title') {
      await this.setState({ editorFocus: 'title', focused: true });
      if (this.titleEditor && !titleState.getSelection().getHasFocus()) {
        this.titleEditor.focus();
      }
    } else {
      await this.setState({ editorFocus: 'body', focused: true });
      if (this.bodyEditor && !editorState.getSelection().getHasFocus()) {
        this.bodyEditor.focus();
      }
    }

    onFocus(section);
  }

  async blur(unlock = false) {
    const { onBlur, section } = this.props;
    const { titleState, editorState } = this.state;
    if (this.titleEditor && titleState && titleState.getSelection().getHasFocus()) {
      this.titleEditor.blur();
    }
    if (this.bodyEditor && editorState && editorState.getSelection().getHasFocus()) {
      this.bodyEditor.blur();
    }
    if (section.shouldSyncWithCL && this.refKBCL.current) {
      this.refKBCL.current.blur();
    }
    await this.setState({ focused: false });
    onBlur(section.id, unlock);
  }

  async deleteSection(moveDirection) {
    const { section, moveFocus } = this.props;
    // Select previous section after deletion if none is specified
    if (!moveDirection) moveDirection = 'up';

    // Move focus prior to deleting so that lock gets removed
    moveFocus(moveDirection);

    await Fire.removeSection(section);

    // As long as section isn't linked to another in the CL,
    // Unlink any that may be linked to *this* section
    if (!section.originCL) {
      API.call('unlinkOriginCL', {
        originCL: `${section.deal.dealID}|${section.id}`,
        teamID: section.deal.template ? section.deal.template.team : section.deal.team,
      });
    }
  }

  handleTitleCommand(command) {
    const { section, updateMessage, source } = this.props;
    const { titleState, editorState } = this.state;

    switch (command) {
      case 'escape':
        this.blur(true);
        return true;
      case 'up':
        this.handleUp('title');
        return true;
      case 'down':
        this.handleDown('title');
        return true;
      case 'indent':
      case 'outdent':
        //tab for indent/outdent does not work on Overview types or on APPENDIX/SIGNATURE
        if ([SectionType.SOURCE, SectionType.LIST].includes(section.sectiontype)) {
          const direction = command === 'indent' ? 'right' : 'left';
          Fire.moveSection(section, direction, true);
        }
        return true;
      case 'save':
        this.save();
        return true;
      case 'reveal-body':
        //for header sections, never reveal body; Enter key means add next section
        if (section.sectiontype == SectionType.HEADER) {
          this.addSection();
          return true;
        }

        //Hitting Enter when cursor is at beginning and there IS title text here
        //means "add a section before this one"
        const hasTitle = titleState.getCurrentContent().hasText();
        const hasBody = this.hasBody;
        const selection = titleState.getSelection();
        if ((hasTitle || hasBody) && selection.getFocusOffset() == 0 && selection.isCollapsed()) {
          this.addSection(true);
        }
        //save section, then rebuild editor state, then focus on it once we're sure it's there
        else {
          this.save(() => {
            this.setState({ editorState: this.createBodyEditorState(section.content) }, () => this.focus('body'));
          });
        }

        return true;
      case 'delete-title':
        //when we get here, we've already confirmed there is no title
        //if there's also no body, delete the section
        //if there is a body, then hide title to re-focus on body
        const body = editorState.getCurrentContent().getPlainText();
        if (!body) {
          //check if this is the only section remaining in the Contract and if so, disallow
          //removal of last remaining Overview section is now allowed as of 12/18/2018
          let allowed = SectionType.src(section.sectiontype) ? source.length > 1 : true;

          if (allowed) {
            this.autosave.cancel();
            updateMessage();
            this.deleteSection('up');
          }
        } else {
          this.save(() => this.focus('body'));
        }
        return true;
      default:
        return false;
    }
  }

  handleBodyCommand(command) {
    const { section } = this.props;
    const { editorState, loadedFVConnectedDefinitions, connectedVariables } = this.state;

    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onBodyChange(newState);
      return true;
    } else {
      switch (command) {
        case 'escape':
          this.blur(true);
          return true;
        case 'up':
          this.handleUp('body');
          return true;
        case 'down':
          this.handleDown('body');
          return true;
        case 'indent':
        case 'outdent':
          //tab for indent/outdent does not work on Overview types or on APPENDIX/SIGNATURE
          if ([SectionType.SOURCE, SectionType.LIST].includes(section.sectiontype)) {
            const direction = command === 'indent' ? 'right' : 'left';
            Fire.moveSection(section, direction, true);
          }
          return true;
        case 'save':
          this.save();
          return true;
        case 'enter':
          //hitting enter on a SIGNATURE, PAYMENT or SCOPE section does nothing
          if ([SectionType.SIGNATURE, SectionType.PAYMENT, SectionType.SCOPE].indexOf(section.sectiontype) > -1)
            return true;

          //note this block needs to be before the next block; otherwise it never executs if there's no body content!
          //(and that makes it impossible to add additional sections at bottom)
          if (isAtEnd(editorState)) {
            //if the current section had no body text, hide the body field
            //as long as it's not a summary
            if (!this.state.editorState.getCurrentContent().hasText() && !this.isSummary)
              this.setState({ editorFocus: 'title' });

            //save current section, then add a new one and move focus to it
            this.save(() => this.addSection());
            return true;
          }

          //enter at beginning when there's no title means add a title for source sections
          //for summary sections it means add/advance to next section
          if (isAtStart(editorState)) {
            //if there's body content but no title showing, show/focus title as long as it's not a summary section
            if (!this.titleEditor && this.state.editorState.getCurrentContent().hasText()) {
              if (this.isSummary) {
                //save current section, then add a new one and move focus to it
                this.save(() => this.addSection());
              } else {
                this.save(() => {
                  this.setState({ titleState: this.createTitleEditorState() }, () => {
                    this.focus('title');
                  });
                });
              }
            }
            return true;
          }

          //if there's content, ENTER in middle of it should split content into 2 sections
          this.splitSection();
          return true;

        case 'split-block':
          return false;
        case 'backspace':
          if (isAtStart(editorState)) {
            //we're at beginning of body and there's no title text
            if (!this.state.titleState.getCurrentContent().hasText()) {
              //if we're looking at a summary section, this means delete the section or move up
              if (this.isSummary) {
                if (!this.state.editorState.getCurrentContent().hasText()) {
                  this.autosave.cancel();
                  //hide status indicator
                  this.props.updateMessage();
                  this.deleteSection('up');
                } else {
                  this.props.moveFocus('up');
                }
              }

              //otherwise, if not showing title field, reveal it
              else if (!this.titleEditor) this.save(() => this.focus('title'));
              //otherwise, if title is already showing, hide it
              else this.save(() => this.focus('body'));
            } else {
              //here, there IS title text so just select at end
              //this time if there's no body text, hide the body field
              if (!this.state.editorState.getCurrentContent().hasText()) {
                this.save(() => this.focus('title'));
              } else {
                //here, we have text in both fields so just move focus
                this.save(() => this.setState({ titleState: EditorState.moveFocusToEnd(this.state.titleState) }));
              }
            }
            return true;
          } else {
            if (
              connectedVariables.length > 0 &&
              !loadedFVConnectedDefinitions &&
              containsEntity(editorState.getCurrentContent(), editorState.getSelection(), [ENTITY_TYPE.VARIABLE])
            ) {
              return true;
            }
            return false; //allow normal backspace behavior
          }
        case 'add-variable':
          this.editEntity(this.bodyEditor, '[#]', true);
          return true;
        default:
          return false;
      }
    }
  }

  addSection(forceBefore) {
    const { section, setQueueFocus } = this.props;
    const isSource = SectionType.src(section.sectiontype);
    let atSection = section;
    let before = false;

    //data method adds section according to hierarchy
    //so adding a section "after" 2 will add it at 3
    //but if the section has children, "after" actually means
    //add it as the first child of this section
    //i.e., before the current first child
    let insertChild = isSource && section.sourceChildren.length > 0 && !forceBefore;
    let newJSON = {};

    //adding to a summary header adds a child, not a sibling
    if (section.sectiontype == SectionType.SUMMARY && section.indentLevel == 0) {
      newJSON.parentid = section.id;
      if (section.children.length > 0) {
        atSection = section.children[0];
        before = true;
      }
    }

    //hitting enter on an APPENDIX section adds a child instead of a sibling
    if (section.sectiontype == SectionType.APPENDIX) {
      newJSON.sourceparentid = section.id;
    }

    if (isSource && section.sourceChildren.length > 0 && !forceBefore) {
      atSection = section.sourceChildren[0];
      before = true;
    }

    //make sure focus moves in the correct direction,
    //depending on whether we're adding before or after
    const onAdd = (newID) => setQueueFocus(newID);

    Fire.addSection(
      atSection, //section around which to add
      isSource, //whether adding to source
      onAdd, //success callback
      newJSON, //root data from special case above
      null, //no updates (it's a new section)
      before || forceBefore //whether adding before or after
    );
  }

  splitSection() {
    const selection = this.state.editorState.getSelection();
    const selectedBlockKey = selection.getAnchorKey();
    const content = this.state.editorState.getCurrentContent();
    const split = Modifier.splitBlock(content, selection).getBlocksAsArray();
    const existingBlocks = [],
      newBlocks = [];
    let addTo = existingBlocks;
    //splitBlock() keeps the existing block keys and creates new blocks as needed
    //so we iterate through the new blocks and *after* we find the key for currently selected,
    //start adding to new array
    split.map((block) => {
      addTo.push(block);
      if (block.key == selectedBlockKey) addTo = newBlocks;
    });

    //now we can get plain text (storable) content for the updated existing section
    //and for a new one to be created
    const existingContent = getMarkup(ContentState.createFromBlockArray(existingBlocks));
    const newContent = getMarkup(ContentState.createFromBlockArray(newBlocks));

    Fire.addSection(
      this.props.section,
      SectionType.src(this.props.section.sectiontype),
      () => {
        this.setState({ editorState: this.createBodyEditorState(existingContent) });
        this.props.moveFocus('down');
      },
      { content: newContent },
      existingContent
    );
  }

  editEntity(target, varText, creating, candidate) {
    //from https://draftjs.org/docs/api-reference-selection-state.html#content
    //if there's something currently selected, pass that into VariableEditor
    const ed = this.state.editorState;
    const sel = ed.getSelection();
    const currentContentBlock = ed.getCurrentContent().getBlockForKey(sel.getAnchorKey());
    const selectedText = currentContentBlock.getText().slice(sel.getStartOffset(), sel.getEndOffset()).trim();

    //we found selected text, so pass that into VariableEditor to make VariableCreation easier

    const isConnected = selectedText.startsWith('[+');
    const isFootnote = selectedText.startsWith('[^');
    const isCalculated = selectedText.startsWith('[%');

    if (selectedText && !isConnected && !isFootnote && !isCalculated) {
      let isRef = false,
        isParty = false;
      [...APPENDIX_NAMES, ...SECTION_NAMES].map(
        (term) => (isRef = isRef || selectedText.toLowerCase().indexOf(term) > -1)
      );
      // ['company','contractor','partner','investor'].map(term => isParty = isParty || selectedText.toLowerCase().indexOf(term) > -1);
      const varType = isRef ? '$' : isParty ? '@' : '#';
      varText = `[${varType}${selectedText}]`;
    }

    this.setState({ entityTarget: target, entity: varText, entityCreating: creating, candidate });
  }

  editColumn(target, field) {
    const { section } = this.props;
    const ds = section.dataSource;
    if (!ds) return;

    field = field.replace(/[{}]/g, '');

    const idx = _.findIndex(ds.columns, { id: field });

    const tc =
      idx > -1
        ? ds.columns[idx]
        : new TableColumn(
            {
              id: field,
              displayName: field,
              valueType: ValueType.STRING,
            },
            ds
          );

    this.setState({
      entityTarget: target,
      editingColumn: tc,
    });
  }

  saveColumn(column) {
    const { section } = this.props;
    const ds = section.dataSource;
    if (!ds) return;

    const idx = _.findIndex(ds.columns, { id: column.id });

    if (idx > -1) {
      ds.columns[idx] = column;
    } else {
      ds.columns.push(column);
    }

    Fire.saveSection(section, { dataSource: ds.json });
  }

  //insert specified text (e.g., from a UI component on the toolbar or wherever) into target field (title or body)
  //this wraps the DraftJS API to insert the block of text and advance the cursor accordingly
  async insertText(text, rerender) {
    const { section } = this.props;

    //grab current selection and content
    let { editorState } = this.state;
    let content = editorState.getCurrentContent();
    let selection = editorState.getSelection();

    //text will be inserted at the start of the selection
    //(which will be same as end if selection is collapsed, i.e., just cursor)
    let cursor = Math.min(selection.getAnchorOffset(), selection.getFocusOffset());

    //generate new ContentState and EditorState objects via Draft API
    content = Modifier.replaceText(content, selection, text);
    const newText = getMarkup(content);

    if (rerender) {
      editorState = this.createBodyEditorState(newText);
      this.save(() => this.setState({ editorState }), newText);
    } else {
      editorState = EditorState.push(editorState, content, 'insert-characters');
      //move cursor forward by the length of the inserted text
      //and apply to editorState
      selection = selection.merge({ anchorOffset: cursor + text.length, focusOffset: cursor + text.length });
      editorState = EditorState.forceSelection(editorState, selection);

      //ensure that editor keeps focus (which it loses if user clicks on a button to insert text)
      if (this.bodyEditor) this.bodyEditor.focus();

      //and then update latest state and persist text AFTER
      //this way the editor never appears to hang -- user can keep editing immediately
      await this.setState({ editorState });
      this.save(null, newText);
      if (this.bodyEditor) this.bodyEditor.focus();
      const vsTarget = !this.state.focused
        ? null
        : findVariableSuggestTarget({
            editorState: this.state.editorState,
            refTargets: this.refSuggestTargets,
            rx: section.isRepeater ? rxSuggestFields : rxSuggest,
          });
      this.setState({ vsTarget });
    }
  }

  handlePaste(text, html) {
    const { section } = this.props;

    //we only want to attempt smartly parsing of html pasting if
    //1) we're on a source section and 2) there's no content here yet
    if (
      html &&
      SectionType.src(section.sectiontype) &&
      !section.isCaption &&
      !section.isTemplateHeaderFooterSubSection &&
      (!section.content || section.content.trim() == '')
    ) {
      const sections = parseExternalHTML(html);

      //now we've got an array of json objects to add as sections
      if (sections.length > 0)
        Fire.addSourceBatch(section, sections, (replaced) => {
          //if content was replaced, we need to update state
          if (replaced) {
            const sec = sections[0];
            this.setState({
              editorState: this.createBodyEditorState(sec.content),
              titleState: this.createTitleEditorState(),
            });
          }
        });

      //prevent default pasting (insertion) -- returning true means paste was handled
      return true;
    }

    //if not html, just allow Draft to manage normal pasting behavior (insert)
    else return false;
  }

  handleUp(field) {
    if (field === 'body' && this.titleEditor) {
      this.titleEditor.focus();
      this.setState({ titleState: EditorState.moveFocusToEnd(this.state.titleState) });
    } else {
      this.props.moveFocus('up');
    }
  }

  handleDown(field) {
    if (field === 'title' && this.bodyEditor) {
      this.bodyEditor.focus();
    } else {
      this.props.moveFocus('down');
    }
  }

  // This is the special keyboard handler for the invisible field
  // To enable keyboard traversal for focused but uneditable (originCL) sections
  handleKeyCL(e) {
    const { section, moveFocus } = this.props;

    if (section.shouldSyncWithCL || !this.hasEditors) {
      switch (e.key) {
        case 'ArrowUp':
          moveFocus('up');
          break;
        case 'ArrowDown':
          moveFocus('down');
          break;
        case 'Escape':
          this.blur(true);
          break;
        case 'Enter':
          this.addSection();
          break;
        default:
          break;
      }
    }
  }

  async clearAndExitClauseLookup() {
    await this.setState({ clauseLookup: false, titleState: EditorState.createEmpty() });
    this.focus('title');
  }

  onClauseLookupKeyDown(e) {
    const { section, keepAlive, updateMessage } = this.props;
    const { clauseLookup, titleState } = this.state;

    const cl = this.refCL.current;

    keepAlive(section.id);

    // Only do inline CL lookup if there's no body text
    if (!this.hasBody && cl && cl.handleKey(e)) return true;

    if (e.key === 'Backspace') {
      if (clauseLookup && titleState.getCurrentContent().getPlainText().trim() === '') {
        this.setState({ clauseLookup: false });
        return true;
      }
    }

    if (e.key === 'Escape') {
      if (clauseLookup) {
        this.clearAndExitClauseLookup();
        return true;
      }
    }

    // In normal cases, just handle keypress with extra commands (enter, tab, etc)
    return titleBindings(e, titleState);
  }

  async onSelectClause(sectionRecord) {
    const { onSelectCL, updateMessage } = this.props;
    updateMessage('Saving...', 'info');
    await onSelectCL(sectionRecord);
    updateMessage();

    this.setState({
      clauseLookup: false,
    });
  }

  handleClickCL() {
    const { section } = this.props;
    if (section.shouldSyncWithCL || !this.hasEditors || this.headerFooterSection.isDefault) {
      this.focus();
    }
  }

  async gainFocus(dir) {
    const { section } = this.props;
    //special case for sections with no editors; direction doesn't matter
    if (!this.hasEditors || section.hasNumberAlignmentOverride) {
      await this.focus();
      return;
    }
    //if coming 'down' from above, focus beginning of first present field
    //if coming 'up' from below, focus end of last present field
    const field = dir === 'down' ? this.titleEditor || this.bodyEditor : this.bodyEditor || this.titleEditor;
    if (!field) return;

    if (dir === 'down') {
      if (field === this.titleEditor) {
        await this.focus('title');
      } else {
        await this.focus('body');
      }
    } else {
      if (field === this.titleEditor) {
        await this.focus('title');
        await this.setState({ titleState: EditorState.moveFocusToEnd(this.state.titleState) });
      } else {
        await this.focus('body');
        await this.setState({ editorState: EditorState.moveFocusToEnd(this.state.editorState) });
      }
    }
  }

  updateSelection(es, field) {
    const { toolbar } = this.props;
    toolbar.current.updateSelection(es, field);
  }

  async save(success, newContent) {
    const { section, updateMessage } = this.props;
    const { editorState, titleState } = this.state;

    const data = {
      content: sanitize(newContent || getMarkup(editorState.getCurrentContent())),
      displayname: sanitize(titleState.getCurrentContent().getPlainText()),
      hideOrder: section.hideOrder,
      conditions: section.conditionsJSON,
    };

    updateMessage('Saving...', 'info');

    try {
      await Fire.saveSection(section, data);
      if (typeof success === 'function') success();
      updateMessage('Saved!', 'info', true);

      // Every time a section in a template is saved, we also need to update any other template sections that may be linked
      // Provided this section is not linked to another CL section (!origi)
      // However there's no need to wait for that call to complete, so as to not block editing on the front-end
      if (section.deal.isTemplate && !section.shouldSyncWithCL) {
        API.call('updateLinkedCL', {
          originCL: `${section.deal.dealID}|${section.id}`,
          title: data.displayname || null,
          body: data.content || null,
          teamID: section.deal.template.team,
        });
      }
    } catch (err) {
      updateMessage('Error saving', 'error');
    }
  }

  // This section has focus if either of its DraftJS editors have focus
  get hasFocus() {
    return this.state.focused;
  }

  get hasBody() {
    const { editorState } = this.state;
    return !!editorState && editorState.getCurrentContent().hasText();
  }

  get hasEditors() {
    return this.showBody || this.showTitle;
  }

  //Returns the parent of the two column config
  get headerFooterSection() {
    const section = this.props.section.isTemplateHeaderFooterSubSection
      ? this.props.section.sourceParent
      : this.props.section;

    return section;
  }

  get className() {
    const { section, linkingSummaryID, lock, overviewMode } = this.props;

    return cx(
      'section-editor',
      section.sectiontype.toLowerCase(),
      { focused: this.hasFocus },
      { linked: section.isSource && linkingSummaryID && this.isLinked },
      { 'linked-elsewhere': section.isSource && linkingSummaryID && this.isLinkedElsewhere },
      { 'overview-mode': overviewMode },
      { [`header-${section.headerType}`]: section.headerType },
      { locked: lock },
      { 'linked-cl': section.shouldSyncWithCL },
      { [`align-${section.style.align}`]: !section.isAppendix },
      { 'model-error': section.modelError },
      { isDefault: this.headerFooterSection.isDefault }
    );
  }

  get isLinked() {
    const { section, linkingSummaryID } = this.props;
    return section.parent && section.parent.id === linkingSummaryID;
  }

  get isLinkedElsewhere() {
    const { section, linkingSummaryID } = this.props;
    return section.parent && section.parent.id !== linkingSummaryID;
  }

  get isLensLinked() {
    const { section, linkingLensID } = this.props;
    if (!linkingLensID) return false;
    const lens = _.get(section, `deal.template.lenses[${linkingLensID}]`);
    if (!lens) return false;
    return lens.relatedSections.includes(section.id);
  }

  get isAIBlockLinked() {
    const { section, linkingAIBlockID } = this.props;
    if (!linkingAIBlockID) return false;
    const aiBlock = section.deal.sections[linkingAIBlockID];

    const linked = !!_.find(aiBlock?.aiPrompt?.linkedSections, (id) => id === section.id);

    return linked;
  }

  get linkingTip() {
    if (this.isLinked) {
      return 'This section is linked to the currently selected overview section. Click to unlink.';
    } else if (this.isLinkedElsewhere) {
      return 'This section is already linked to another overview. Click to link it to the currently selected overview section instead.';
    } else {
      return 'Click to link this section to the currently selected overview section.';
    }
  }

  //for source side to know whether to enable linking button
  get canBeSummarized() {
    const { linkingSummaryID, section } = this.props;
    if (!linkingSummaryID) return false;
    //focused section must be a summary at level 1 indent
    const focusedSection = section.deal.sections[linkingSummaryID];
    if (!focusedSection) return false;

    switch (focusedSection.sectiontype) {
      //if the focused section is a SUMMARY it has to be at indentLevel 1
      //and THIS section is only linkable if it is a regular source section
      case SectionType.SUMMARY:
        return (
          focusedSection.indentLevel == 1 &&
          [SectionType.SOURCE, SectionType.LIST].includes(section.sectiontype) &&
          !section.isCaption
        );
      //SCOPE and PAYMENT are UGC sections, so can only be linked to APPENDIX types
      case SectionType.SCOPE:
      case SectionType.PAYMENT:
        return section.sectiontype == SectionType.APPENDIX;
      //NB: SIGNATURE sections are currently not linkable as they only appear on the full contract view
      default:
        return false;
    }
  }

  // Only show AI Link buttons if the currently selected AI Block's dsType is Sections (default/legacy)
  // If it's linked to Variables instead, don't show the button
  get canBeAILinked() {
    const { section, linkingAIBlockID } = this.props;
    const focusedSection = section.deal.sections[linkingAIBlockID];

    if (
      linkingAIBlockID &&
      linkingAIBlockID !== section.id &&
      focusedSection?.aiPrompt?.dsType === DATA_SOURCE_TYPES.SECTIONS
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isTitleFocused() {
    const { titleState } = this.state;
    return titleState && titleState.getSelection().getHasFocus();
  }

  get isBodyFocused() {
    const { editorState } = this.state;
    return editorState && editorState.getSelection().getHasFocus();
  }

  placeHolder = (isTitle) => {
    const { section } = this.props;
    const isInAppendix = section.appendix;
    const isCaption = section.isCaption;
    const name = isTitle ? 'Title' : 'Body';

    switch (section.sectiontype) {
      case SectionType.LIST:
        const listType = FEATURE_LABEL[section.subType] || 'List';
        if (section.isRepeater && !isTitle) {
          return "Enter template for Repeater items; use '{' to insert available fields";
        }

        let listPlaceholder = `${listType} ${name}`;
        listPlaceholder = isInAppendix ? `Appendix ${listType} ${name}` : listPlaceholder;
        listPlaceholder =
          section.sourceParent.sectiontype === SectionType.SIGNATURE
            ? `Signature ${listType} ${name}`
            : listPlaceholder;
        return listPlaceholder;
      case SectionType.HEADER:
        return `Header (${section.headerType.toUpperCase()})`;
      case SectionType.APPENDIX:
        return `Appendix ${name}`;
      case SectionType.SIGNATURE:
        return `Signature ${name}`;
      case SectionType.TEMPLATE_HEADER:
      case SectionType.TEMPLATE_FOOTER:
        if (section.isTemplateHeaderFooterSubSection) {
          return `${section?.sourceParent?.displayname} (Enter text)`;
        }
        return section?.displayname || `Enter text`;
      default:
        const isInOverview = section.isSummary;
        let placeholder = `Paragraph ${name}`;

        if (isInAppendix) {
          placeholder =
            section.sourceParent.sectiontype === SectionType.SIGNATURE
              ? `Signature Item ${name}`
              : `Appendix Item ${name}`;
          if (isCaption) {
            placeholder =
              section.sourceParent.sourceParent.sectiontype === SectionType.SIGNATURE
                ? `Signature Caption ${name}`
                : `Appendix Caption ${name}`;
          }
        } else {
          placeholder = isCaption ? 'Caption Body' : placeholder;
        }

        if (isTitle) {
          return isInOverview ? (
            'Overview Title'
          ) : (
            <span>
              {placeholder} <span className="cl-lookup-text">Type '/' for Clause lookup</span>
            </span>
          );
        } else {
          if (isInOverview) {
            const isSubtitle = _.find(section.styleTypes, { key: 'OverviewSubtitle' });
            return isSubtitle ? 'Overview Subtitle' : 'Overview Body';
          } else {
            return placeholder;
          }
        }
    }
  };

  // If this section is either a continuous list, or has conditions, we can't generate a true number in Draft
  // because we don't know what it will be (dependent on Flow)
  // So show a '#' as a number placeholder (https://trello.com/c/aOG2Q6fo)
  get phNumber() {
    const { section } = this.props;
    return section.isNumberPlaceholder || !!section.conditions.length ? '#' : null;
  }

  toggleSectionLink(e) {
    if (e) e.stopPropagation();
    const { linkingSummaryID, section } = this.props;

    if (linkingSummaryID && SectionType.src(section.sectiontype)) {
      Fire.toggleSectionLink(linkingSummaryID, section, !this.isLinked);
    }
  }

  async toggleAIBlockLink(e) {
    if (e) e.stopPropagation();
    const { linkingAIBlockID, section } = this.props;
    if (!linkingAIBlockID) return;

    const aiBlock = section.deal.sections[linkingAIBlockID];

    if (!this.isAIBlockLinked) {
      aiBlock.aiPrompt.linkedSections.push(section.id);
    } else {
      _.remove(aiBlock.aiPrompt.linkedSections, (id) => id === section.id);
    }

    Fire.toggleAIBlockLink(aiBlock);
  }

  async toggleLensLink(e) {
    if (e) e.stopPropagation();
    const { linkingLensID, section } = this.props;
    const lens = _.get(section, `deal.template.lenses[${linkingLensID}]`);
    if (!linkingLensID || !lens) return;

    lens.relatedSections.pop();
    if (!this.isLensLinked) {
      lens.relatedSections.push(section.id);
    }

    await Fire.saveLens(section.deal.dealID, lens);
  }

  updateSigConfig(action, partyID) {
    const { section } = this.props;
    let sigs = section.signatories;
    const idx = sigs.indexOf(partyID);

    switch (action) {
      case 'left':
        sigs.splice(idx, 1);
        sigs.splice(idx - 1, 0, partyID);
        break;
      case 'right':
        sigs.splice(idx, 1);
        sigs.splice(idx + 1, 0, partyID);
        break;
      case 'remove':
        sigs.splice(idx, 1);
        break;
      case 'add':
        sigs.push(partyID);
        break;
      default:
        break;
    }

    Fire.saveSection(section, { signatories: sigs.join(',') });
  }

  updateLinkedTemplate(template) {
    const { section } = this.props;
    const sourceTemplateKey = _.get(template, 'sourceTemplateKey', null);
    const content = _.get(template, 'title', null);

    Fire.saveSection(section, { linkedTemplate: sourceTemplateKey, content });
  }

  onHideVariableEditor() {
    this.setState({ entity: null, entityTarget: null });
  }

  onSaveVariableEditor(newText) {
    this.insertText(newText, true);
  }

  renderSig() {
    const { section } = this.props;

    const signatories = section.signatories;
    const unusedSignatories = [];

    if (signatories.length !== section.deal.parties.length) {
      section.deal.parties.map((p) => {
        if (!signatories.includes(p.partyID)) unusedSignatories.push(p);
      });
    }

    return (
      <div className="ugc-signature">
        {signatories.map((p, i) => {
          // Section.signatories is an array of partyIDs
          // So lookup underlying Party for better display
          // Can still be null though if it just got deleted from a Signature Section
          const partySignatory = section.deal.getPartyByID(p);
          const isMissing = partySignatory == null;
          const displayParty = isMissing ? p : partySignatory.displayName;

          return (
            <div className={`sig-block informative${isMissing ? ' missing' : ''}`} key={i}>
              <div className="party-name">{displayParty}</div>
              <div className="instructions">Signature block will appear here</div>
              <ButtonGroup className="party-actions">
                <TooltipButton tipID={`${section.id}-${i}-left`} tip={`Move signature block left`} disabled={i == 0}>
                  <Button
                    disabled={i == 0}
                    onClick={() => this.updateSigConfig('left', p)}
                    size="small"
                    icon="chevronLeft"
                  />
                </TooltipButton>
                <TooltipButton
                  tipID={`${section.id}-${i}-right`}
                  tip={`Move signature block right`}
                  disabled={i + 1 == signatories.length}
                >
                  <Button
                    disabled={i + 1 == signatories.length}
                    onClick={() => this.updateSigConfig('right', p)}
                    size="small"
                    icon="chevronRight"
                  />
                </TooltipButton>
              </ButtonGroup>
              {signatories.length > 1 && (
                <TooltipButton
                  tipID={`${section.id}-${i}-remove`}
                  tip={`Remove ${displayParty} from this signature section`}
                >
                  <Button className="remove" onClick={() => this.updateSigConfig('remove', p)}>
                    ✕
                  </Button>
                </TooltipButton>
              )}
            </div>
          );
        })}
        {unusedSignatories.length > 0 && (
          <div className="add-signatories">
            <span className="instructions">Add signatories to this signature section:</span>
            {unusedSignatories.map((p) => (
              <Button key={p.partyID} dmpStyle="link" onClick={() => this.updateSigConfig('add', p.partyID)}>
                {p.displayName}
              </Button>
            ))}
          </div>
        )}
      </div>
    );
  }

  renderList() {
    const { section: list } = this.props;

    return (
      <div className={cx('ugc-list', { indented: list.indent })}>
        <Alert dmpStyle="info" size="small">
          <Icon name={list.subType.toLowerCase()} />
          {list.name && <strong>[{list.name}] </strong>}
          {list.isRepeater &&
            (!list.dataSource
              ? 'Select a data source to enable this Repeater'
              : 'Content will render when document is generated')}
          {[LIST_TYPES.BLOCK, LIST_TYPES.LIST].includes(list.subType) &&
            'Content entered by users in Flow will appear here.'}
          {list.isAI && 'AI generated content will appear here in Flow.'}
        </Alert>
      </div>
    );
  }

  renderClauseLookup() {
    const { titleState } = this.state;

    return (
      <Editor
        editorState={titleState}
        spellCheck={true}
        onChange={(s) => this.onTitleChange(s)}
        placeholder="Enter keywords to search"
        keyBindingFn={(e) => this.onClauseLookupKeyDown(e)}
      />
    );
  }

  render() {
    const {
      section,
      readonly,
      userID,
      team,
      lock,
      unlinkCL,
      variableIndex,
      overviewMode,
      onColConfig,
      templateRef,
      linkingLensID,
    } = this.props;
    const { candidate, vsTarget, titleState, focused, entity, editingColumn, entityTarget, clauseLookup } = this.state;

    if (!section) return null;

    const { isCaption, isColumn, pageBreak, activeFootnotes } = section;
    const tableVariables = _.filter(section.variables, { valueType: ValueType.TABLE });

    //these get populated upon render via ref functions depending on which fields are showing
    this.titleEditor = null;
    this.bodyEditor = null;

    //UGC placeholder needs to appear visually at the bottom of an appendix section
    //this can either be the appendix section itself (if no children)
    //or its last child if it has content
    let appendixEnd = false,
      showUGC = false;
    const isInAppendix = section.appendix;

    const isSig = section.sectiontype === SectionType.SIGNATURE;
    const isList = section.isList;
    const isOverviewList = isList && overviewMode;
    const summaryCount = section.summaryCount;

    if (isInAppendix) {
      appendixEnd = section == isInAppendix.lastChild;
      if (isInAppendix == section && isInAppendix.parent != null) showUGC = true;
    }

    let c = this.className;
    if (appendixEnd) {
      c += ' appendix-end';
    }
    if (showUGC) c += ' show-ugc';
    if (pageBreak && !isCaption && !isColumn && !section.isTemplateHeaderFooterSubSection) c += ' page-break';

    // Text alignment is managed at the Section level, but needs to be merged into each of the title/body
    const styleTitle = section.styleTitle.css;
    //Template Headers and Footers in 2 col mode will utilize their parents style

    let styleBody = section.isTemplateHeaderFooterSubSection
      ? section.sourceParent.styleBody.css
      : section.styleBody.css;

    console.log('a isAligned?', section.displayNumber, !!section.isTemplateHeaderFooterSubSection, { ...styleBody });

    // Styles specific to editor
    // styleTitle.gridColumn = 2;
    // styleTitle.gridRow = 1;

    console.log('b isAligned?', section.displayNumber, section.style.isAligned, section.style.align);

    if (section.style.isAligned && !section.displayNumber) {
      console.log('c isAligned?');
      styleTitle.textAlign = section.style.align;
      styleBody.textAlign = section.style.align;
    }

    if (section.isAppendix) {
      console.log('c1 isAligned?');
      if (section.sectionStyle.isAligned) {
        console.log('c2 isAligned?');
        styleTitle.textAlign = section.sectionStyle.align;
        styleBody.textAlign = section.sectionStyle.align;
      } else {
        //for legacy themes prior to the type being modified
        styleBody.textAlign = styleTitle.textAlign;
      }
    } else {
      console.log('e isAligned? merging', section.displayNumber, { ...styleBody }, { ...section.webContentLayout });
      styleBody = { ...styleBody, ...section.webContentLayout };
    }

    // Special case if we're looking at a linked LIST section in overview
    // We want to align it with its "parent" (which is actually rendered as previous child)
    const containerStyle = _.merge({}, section.webLayout);

    let textStyle = {};
    if (section.showOrder) {
      console.log('showOrderz a', section.displayTitle, section?.themeIndentation, this.state.editorFocus);
      if (!section?.themeIndentation /*|| this.state.editorFocus === 'title'*/) {
        console.log('showOrderz b');
        textStyle = {
          display: 'grid',
          gridTemplateColumns: 'max-content 1fr',
          border: '1px solid #ff0',
        };
      } else {
        console.log('showOrderz c');
        textStyle = { display: 'inline', border: '1px solid #00f' };
      }

      if (section.displayTitle) {
        styleBody.gridArea = '2 / 2';
      }

      if (!document.cookie.split('; ').includes('debug=true')) {
        delete textStyle.border;
      }
    }

    const tabWidth = _.get(section.deal.style, 'layout.Indent.web.left', 0);

    if (isOverviewList && section.parent.showOrder) {
      containerStyle.paddingLeft += tabWidth;
    }

    let alignedNumberStyle = {};
    if (section && section.hasNumberAlignmentOverride) {
      alignedNumberStyle = { width: '100%', textAlign: section.alignment };
      containerStyle['flex-direction'] = 'column';
    }

    const showClause = clauseLookup && !isOverviewList && focused;

    if (!section.showOrder && !showClause) {
      styleTitle.gridColumn = 'span 2';
    }

    return (
      <>
        <div
          data-sectionid={section.id}
          className={c}
          onClick={this.handleClickCL}
          ref={this.refSelf}
          style={containerStyle}
          data-cy={`${section.sectiontype.toLowerCase()}-section-editor`}
        >
          <>
            {clauseLookup &&
              focused &&
              section.canCL &&
              !section.shouldSyncWithCL &&
              section.currentVersion.isPlaceholder && (
                <SectionSuggest
                  contextual
                  ref={this.refCL}
                  teamID={section.deal.team}
                  input={titleState.getCurrentContent().getPlainText()}
                  onSelect={(sectionRecord) => this.onSelectClause(sectionRecord)}
                  section={section}
                  addNewClause={() => this.setState({ clauseLookup: false })}
                />
              )}

            {pageBreak && !isCaption && !section.isTemplateHeaderFooterSubSection && !isColumn && (
              <PageBreak section={section} />
            )}
            {(section.shouldSyncWithCL || !this.hasEditors) && (
              <input
                className="cl-key-handler"
                ref={this.refKBCL}
                value=""
                onKeyDown={this.handleKeyCL}
                onChange={_.noop}
              />
            )}

            <div style={textStyle} className="section-text" data-cy="section-text">
              {section.showOrder && !isOverviewList && (
                <div
                  className="section-number"
                  style={{
                    ...section.styleNumber.css,
                    ...alignedNumberStyle,
                    float: 'left',
                    lineHeight: styleBody.lineHeight,
                    position:
                      !section.displayTitle &&
                      this.state.editorFocus !== 'title' &&
                      section.indentLevel >= section?.themeIndentation?.wrap &&
                      section.themeIndentation.type !== INDENT_TYPES.NONE
                        ? 'absolute'
                        : 'relative',
                  }}
                  data-cy="section-number"
                  onClick={() => this.focus('title')}
                >
                  <TooltipButton
                    disabled={!this.phNumber}
                    tip={
                      section.isNumberPlaceholder
                        ? 'List items will inherit document-level numbering'
                        : 'Conditional logic will determine the number on section'
                    }
                  >
                    <span>{this.phNumber || section.displayNumber}</span>
                  </TooltipButton>
                </div>
              )}

              {this.canBeSummarized && (
                <TooltipButton tipID={`link-${section.id}`} tip={this.linkingTip}>
                  <ButtonIcon
                    active={this.linked}
                    className={cx('link-toggler', { 'linked-elsewhere': this.isLinkedElsewhere })}
                    onClick={this.toggleSectionLink}
                    icon={this.isLinked || this.isLinkedElsewhere ? 'link' : 'noLink'}
                    size="default"
                    data-cy="link-toggler"
                  />
                </TooltipButton>
              )}

              {this.canBeAILinked && (
                <ButtonIcon
                  className={cx('link-toggler', { 'linked-vital': this.isAIBlockLinked })}
                  onClick={this.toggleAIBlockLink}
                  icon={this.isAIBlockLinked ? 'link' : 'noLink'}
                  size="default"
                  data-cy="link-toggler"
                />
              )}

              {!!linkingLensID && (
                <ButtonIcon
                  className={cx('link-toggler', { 'linked-vital': this.isLensLinked })}
                  onClick={this.toggleLensLink}
                  icon={this.isLensLinked ? 'link' : 'noLink'}
                  size="default"
                  data-cy="vital-link-toggler"
                />
              )}

              {showClause && (
                <div className="section-title" style={styleTitle} data-cy="section-title">
                  {this.renderClauseLookup()}
                </div>
              )}

              {!showClause && this.showTitle && !isOverviewList && !section.hasNumberAlignmentOverride && (
                <div className="section-title" style={styleTitle} data-cy="section-title">
                  <Editor
                    onFocus={() => this.focus('title')}
                    readOnly={readonly}
                    stripPastedStyles={true}
                    spellCheck={true}
                    handlePastedText={(text, html) => this.handlePaste(text, html)}
                    editorState={this.state.titleState}
                    onChange={(s) => this.onTitleChange(s)}
                    placeholder={this.placeHolder(true)}
                    keyBindingFn={this.onTitleKeyDown}
                    handleKeyCommand={(c) => this.handleTitleCommand(c)}
                    ref={(r) => (this.titleEditor = r)}
                  />
                </div>
              )}

              <div style={styleBody} data-cy="section-body">
                {section.showOrder && this.showBody && !isOverviewList && section?.themeIndentation && (
                  <div
                    className="section-number"
                    style={{
                      ...section.styleNumber.css,
                      ...alignedNumberStyle,
                      visibility:
                        typeof document !== 'undefined' && document.cookie.split('; ').includes('debug=true')
                          ? 'visible'
                          : 'hidden',
                      float: 'left',
                      background: 'rgba(255,0,255,0.5)', //#f0f
                      color: 'transparent',
                      textIndent: 0,
                      position:
                        section?.themeIndentation.type === INDENT_TYPES.NONE &&
                        section.indentLevel >= section?.themeIndentation.wrap
                          ? 'absolute'
                          : 'relative',
                      pointerEvents: 'none',
                    }}
                  >
                    <span>{this.phNumber || section.displayNumber}</span>
                  </div>
                )}

                {!showClause && this.showBody && !isOverviewList && !section.hasNumberAlignmentOverride && (
                  <Editor
                    onFocus={() => this.focus('body')}
                    readOnly={readonly}
                    spellCheck={true}
                    stripPastedStyles={true}
                    handleBeforeInput={this.maskedEntityCheck}
                    handlePastedText={this.handlePaste}
                    blockStyleFn={() => 'section-body'}
                    editorState={this.state.editorState}
                    onChange={this.onBodyChange}
                    placeholder={this.placeHolder(false)}
                    keyBindingFn={this.onBodyKeyDown}
                    handleKeyCommand={this.handleBodyCommand}
                    ref={(r) => (this.bodyEditor = r)}
                  />
                )}

                {section.appendixType === SectionType.LINKED && team && (
                  <TemplateSelector
                    className="linked-appendix-selector"
                    id={`template-selector-linked-${section.id}`}
                    team={team}
                    onSelect={this.updateLinkedTemplate}
                    selectedTemplateKey={section.linkedTemplate?.key || null}
                    size="medium"
                  />
                )}

                {_.map(tableVariables, (table, idx) => (
                  <TableView key={idx} section={section} text={table.name} variable={table} />
                ))}

                {SectionType.ui(section.sectiontype) && (
                  <div className="ugc-note informative">
                    {Dt}-specific content will be entered here. View Preview for example.
                  </div>
                )}

                {isList && this.renderList()}

                {!!section.conditions.length && !section.isTemplateHeaderFooter && (
                  <div onClick={() => this.focus('body')}>
                    <ConditionsView conditions={section.conditions} deal={section.deal} section={section} />
                  </div>
                )}
              </div>

              {lock && section.deal.can(userID, 'edit') && (
                <LockTakeover
                  lock={lock}
                  section={section}
                  handleLock={this.props.handleLockSection}
                  handleUnlock={() => {
                    this.props.handleUnlockSection(section.id);
                    this.props.focusSection(section.id);
                  }}
                />
              )}

              {section.shouldSyncWithCL && (
                <TooltipButton tip="This section is linked to another clause in a different template">
                  <div className="locked-icon" data-cy="locked-icon" onClick={() => unlinkCL(section)}>
                    <Icon name="link" />
                  </div>
                </TooltipButton>
              )}

              {/*Static markup for usability for APPENDIX and SIGNATURE type sections */}
              {showUGC /*appendixEnd &&*/ && (
                <div className="ugc-appendix informative" data-cy="ugc-appendix-information">
                  <div className="instructions">{Dt}-specific content will appear here. View Preview for example.</div>
                </div>
              )}

              {this.isSummary && summaryCount > 0 && (
                <TooltipButton
                  tip={`This section is linked to ${summaryCount} ${dt} section${summaryCount > 1 ? 's' : ''}`}
                >
                  <div className="linked-summary" data-cy="linked-summary">
                    <Icon name="link" />
                  </div>
                </TooltipButton>
              )}

              {isSig && this.renderSig()}

              {vsTarget && (
                <VariableSuggest
                  ref={this.refSuggest}
                  variableIndex={variableIndex}
                  deal={section.deal}
                  input={vsTarget.input}
                  onSelect={this.commitSuggestion}
                  target={vsTarget.target}
                  group={_.get(section, 'dataSource.externalSelector', null)}
                  section={section}
                />
              )}

              {entity && entityTarget && (
                <VariableEditor
                  rootClose
                  creating={this.state.entityCreating}
                  candidate={candidate}
                  container={templateRef.current}
                  deal={this.props.section.deal}
                  toc={this.props.toc}
                  text={this.state.entity}
                  target={this.state.entityTarget}
                  hide={this.onHideVariableEditor}
                  onSave={this.onSaveVariableEditor}
                  onColConfig={onColConfig}
                  variableIndex={variableIndex}
                />
              )}

              {editingColumn && entityTarget && (
                <TableColumnEditor
                  column={editingColumn}
                  target={entityTarget}
                  container={this.refSelf.current}
                  onSave={this.saveColumn}
                  hide={() => this.setState({ editingColumn: null })}
                  variableIndex={variableIndex}
                />
              )}
            </div>
          </>
        </div>
        {activeFootnotes.length > 0 && !isCaption && !isColumn && (
          <FootnoteDisplay
            section={section}
            styles={_.merge(containerStyle, section.footnoteStyle.css)}
            activeFootnotes={activeFootnotes}
            container={templateRef.current}
          />
        )}
      </>
    );
  }
}
